import { Component, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, QueryParamsHandling, Router } from '@angular/router';
import { datadogRum } from '@datadog/browser-rum';
import { Subscription, forkJoin } from 'rxjs';
import { SeAnalyticsService } from 'se-analytics';
import { SeFeToastService, SeFeToastTypes } from 'se-fe-toast';
import { LoadScriptsService } from 'src/app/services/load-scripts.service';
import { LaunchDarklyService } from '../../discounts/services/launch-darkly.service';
import { CURRENT_ORG_ID } from '../../providers/current-org-id.provider';
import { Item } from '../models/item.model';
import { PagingService } from '../pagination/paging.service';
import { ItemService } from '../services/item.service';
import { StoreStatusService } from '../services/store-status.service';

@Component({
  selector: 'app-item-list',
  providers: [PagingService],
  styleUrls: ['./item-list.component.scss'],
  templateUrl: './item-list.component.html',
})
export class ItemListComponent implements OnInit, OnDestroy {
  items: Item[] = [];
  itemsStatistics: Record<string, { variations_count: number }> = {};
  queryParamSubscription: Subscription;
  hasSku = false;
  loading = true;
  searchDirtied = false;
  showOverlay: boolean;
  showVariations: boolean;
  searchTerm = '';
  sortColumn = 'name';
  sortDirection = 'asc';
  showItemModal = false;
  storeStatusSubscription: Subscription;
  showTaxCode = false;
  earlyLatePricingEnabled = false;

  constructor(
    @Inject(CURRENT_ORG_ID) public currentOrgId: string,
    private itemService: ItemService,
    private pagingService: PagingService,
    private storeStatusService: StoreStatusService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ngZone: NgZone,
    private toastService: SeFeToastService,
    private seAnalyticsService: SeAnalyticsService,
    private loadScriptsService: LoadScriptsService,
    private launchDarkly: LaunchDarklyService
  ) {
    this.earlyLatePricingEnabled = this.launchDarkly.enabled('early-late-pricing');
  }

  search(searchTerm: string): void {
    this.searchTerm = searchTerm.trim();
    if (this.searchTerm.length === 0) {
      this.searchDirtied = false;
      this.navigate({ search_term: '', page: 1 });
      this.loadItems();
      return;
    }
    this.searchDirtied = true;
    this.navigate({ search_term: this.encodedSearchTerm(), page: 1 });
  }

  loadItems(): void {
    this.showOverlay = true;
    forkJoin([
      this.itemService.getItemsStatistics(this.combinedApiParams()),
      this.itemService.getItems(this.combinedApiParams()),
    ]).subscribe(([itemStatistics, items]) => {
      itemStatistics.result.map((itemStats) => {
        this.itemsStatistics[itemStats.id] = itemStats as any;
      });
      this.items = (items.result || []).map((item) => new Item(item));
      this.pagingService.collect(items.metadata.pagination);
      this.hasSku = this.checkForSku();
      this.setShowVariations();
      this.loading = false;
      this.showOverlay = false;
    });
  }

  navigate(params: Params, strategy: QueryParamsHandling = 'merge'): void {
    this.ngZone.run(() => {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: params,
        queryParamsHandling: strategy,
      });
    });
  }

  ngOnInit(): void {
    this.loadScriptsService.loadScripts('saleItem');
    this.subscribeToRouteParams();
    this.subscribeToStoreStatus();
    if (!this.storeStatusService.isSet) {
      this.storeStatusService.setStoreStatus(this.currentOrgId);
    }
    this.seAnalyticsService.trackEvent('pageView', {
      depth1: 'HQ',
      depth2: 'SaleItems',
    });
    datadogRum.addAction('Item List', {
      currentOrgId: this.currentOrgId,
      items: this.items,
    });
  }

  private subscribeToStoreStatus(): void {
    this.storeStatusSubscription = this.storeStatusService.$storeStatus.subscribe((storeStatus) => {
      this.showTaxCode = storeStatus.taxable;
    });
  }

  combinedApiParams(): Params {
    const params = {
      organization_id: this.currentOrgId,
      exclude_fees: true,
      search_fields: 'items.name,item_variations.sku',
      search_term: this.searchTerm ? decodeURIComponent(this.encodedSearchTerm()) : '',
    };
    params[this.sortColumnApiName(this.sortColumn)] = this.sortDirection;
    return this.pagingService.toApiParams(params);
  }

  private sortColumnApiName(colname: string): string {
    return 'order[' + colname + ']';
  }

  encodedSearchTerm(): string {
    const normalizedSearchTerm = this.searchTerm.trim().replace(/[\^$.*+?()[\]{}|\\]/g, '\\$&');
    return encodeURIComponent(normalizedSearchTerm);
  }

  subscribeToRouteParams(): void {
    this.queryParamSubscription = this.activatedRoute.queryParamMap.subscribe((params) => {
      if (params.get('search_term')) {
        this.searchTerm = decodeURIComponent(params.get('search_term'));
      }
      this.loadItems();
    });
  }

  isActiveColumn(sortKey: string): boolean {
    return this.sortColumn === sortKey;
  }

  sortColumns(columnKey: string): void {
    if (columnKey !== this.sortColumn || this.sortDirection === 'desc') {
      this.sortDirection = 'asc';
    } else {
      this.sortDirection = 'desc';
    }
    this.sortColumn = columnKey;
    const sortCol = this.sortColumnApiName(this.sortColumn);
    const params = {
      page: 1,
      search_term: decodeURIComponent(this.encodedSearchTerm()),
    };
    params[sortCol] = this.sortDirection;
    this.navigate(params, '');
  }

  moreThanOneVariation(id: string): boolean {
    return this.itemsStatistics[id].variations_count > 1;
  }

  setSortArrow(sortKey: string): string {
    if (!this.isActiveColumn(sortKey)) {
      return 'custom_sort_up_down';
    } else if (this.sortDirection === 'asc') {
      return 'custom_sort_up';
    } else {
      return 'custom_sort_down';
    }
  }

  checkForSku(): boolean {
    return this.items.some((item: Item) => !!item.sku);
  }

  listIsEmpty(): boolean {
    return !this.items.length && !this.searchTerm && !this.searchDirtied;
  }

  searchedListIsEmpty(): boolean {
    return !this.items.length && (!!this.searchTerm || this.searchDirtied);
  }

  ngOnDestroy(): void {
    this.pagingService.unsubscribe();
    this.queryParamSubscription.unsubscribe();
  }

  onSuccess(item: Item): void {
    this.loadItems();
    this.toastService.addToast({
      type: SeFeToastTypes.Success,
      message: `"${item.name}" sale item added`,
    });
  }

  setShowVariations(): void {
    this.showVariations = this.items.some((item: Item) => this.itemsStatistics[item.id]?.variations_count > 1);
  }

  variationsDisplayCount(item: Item): number | string {
    const { variations_count } = this.itemsStatistics[item.id];
    return variations_count > 1 ? variations_count : '--';
  }

  public toggleItemModal(): void {
    this.showItemModal = !this.showItemModal;
  }
}
