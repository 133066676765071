<se-fe-page SeFeDataWidth="full">
  <se-fe-header seFePageHeader [seFeDataTitle]="'DISCOUNTS.ADD_DISCOUNT.title' | translate" size="750">
    <se-fe-icon-button seFeHeaderActions>
      <se-fe-icon
        data-dd-action-name="Close Discount"
        seFeDataName="remove"
        seFeDataSize="700"
        [seFeDataAriaLabel]="'DISCOUNTS.ADD_DISCOUNT.close' | translate"
        [routerLink]="'/discounts'"></se-fe-icon>
    </se-fe-icon-button>
  </se-fe-header>

  <div seFePageContent>
    <app-discount-form [formMode]="'create'" [discount]="discount"></app-discount-form>
  </div>
</se-fe-page>
