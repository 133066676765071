// Angular imports
import { CurrencyPipe } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

// Models
import { ApiResponse } from '../models/api-response.model';
import { ItemVariation } from '../models/item-variation.model';
import { Item } from '../models/item.model';
import { SaleItem } from '../models/sale-item.model';
import { SalesSummary } from '../models/sales-summary.model';

// Custom services
import { SeAnalyticsService } from 'se-analytics';
import { CURRENT_ORG_ID } from '../../providers/current-org-id.provider';
import { Sale } from '../models/sale.model';
import { PagingService } from '../pagination/paging.service';
import { ItemVariationService } from '../services/item-variation.service';
import { ItemService } from '../services/item.service';
import { SaleItemService } from '../services/sale-item.service';
import { StoreStatusService } from '../services/store-status.service';
import { datadogRum } from '@datadog/browser-rum';

@Component({
  selector: 'app-item-variation-detail',
  styleUrls: ['./item-variation-detail.component.scss'],
  templateUrl: './item-variation-detail.component.html',
})
export class ItemVariationDetailComponent implements OnInit, OnDestroy {
  variation: ItemVariation;
  variationId: number;
  item: Item;
  itemId = this.activatedRoute.snapshot.params.saleItemId;
  saleItems: SaleItem[];
  saleItemLoaded = false;
  showOverlay: boolean;
  itemLoaded = false;
  salesSummary: string;
  queryParamSubscription: Subscription;
  externalBaseUrl = environment.snFrontendBaseUrl;
  upfrontAmountSettingText: string;
  showUpfrontAmountText = false;

  constructor(
    @Inject(CURRENT_ORG_ID) public currentOrgId: string,
    private activatedRoute: ActivatedRoute,
    private itemService: ItemService,
    private itemVariationService: ItemVariationService,
    private saleItemService: SaleItemService,
    private currencyPipe: CurrencyPipe,
    private pagingService: PagingService,
    private storeStatusService: StoreStatusService,
    private seAnalyticsService: SeAnalyticsService
  ) {}

  ngOnInit() {
    this.variationId = parseInt(this.activatedRoute.snapshot.params.variationId, 10);
    if (!this.storeStatusService.isSet) {
      this.storeStatusService.setStoreStatus(this.currentOrgId);
    }
    this.loadItem();
    this.subscribeToRouteParams();
    this.seAnalyticsService.trackEvent('pageView', {
      depth1: 'HQ',
      depth2: 'SaleItems',
      depth3: 'Detail',
      depth4: 'Variations',
      depth5: 'Detail',
    });
    datadogRum.addAction('Viewing Item Variation Detail', {
      variationId: this.variationId,
      itemId: this.itemId,
      saleItems: this.saleItems,
      saleItemLoaded: this.saleItemLoaded,
    });
  }

  ngOnDestroy() {
    this.pagingService.unsubscribe();
    this.queryParamSubscription.unsubscribe();
  }

  public setVariation(item: Item): void {
    this.variation = new ItemVariation(
      item.variations.find((variation: ItemVariation) => variation.id === this.variationId)
    );
  }

  loadItem(): void {
    this.itemLoaded = false;
    this.itemService.getItem(this.itemId).subscribe(
      (response) => this.loadItemSuccess(response),
      (error) => this.loadItemError(error)
    );
  }

  loadItemSuccess(response: ApiResponse<Item>): void {
    this.item = new Item(response.result);
    this.setVariation(this.item);
    this.showUpfrontAmountText = this.variation.price_cents > 0;
    if (this.showUpfrontAmountText) {
      this.upfrontAmountSettingText = this.itemVariationService.upfrontAmountSettingText(this.variation);
    }
    this.itemLoaded = true;
  }

  loadItemError(error: Error): void {
    console.error('loadItemError: ', error);
    throw error;
  }

  subscribeToRouteParams(): void {
    this.queryParamSubscription = this.activatedRoute.queryParamMap.subscribe((params) => {
      this.loadSaleItems();
    });
  }

  combinedApiParams(): Params {
    const params = {
      item_variation_id: this.variationId,
      sold: true,
    };
    return this.pagingService.toApiParams(params);
  }

  loadSaleItems(): void {
    this.showOverlay = true;
    this.saleItemService.getSaleItems(this.combinedApiParams()).subscribe((response) => {
      this.loadSaleItemsSuccess(response);
    });
  }

  loadSaleItemsSuccess(response: ApiResponse<SaleItem[]>): void {
    this.saleItems = response.result.map((saleItem) => new SaleItem(saleItem));
    this.pagingService.collect(response.metadata.pagination);
    this.generateSalesSummary(response.metadata.summary);
    this.saleItemLoaded = true;
    this.showOverlay = false;
  }

  getSaleUrl(sale: Sale) {
    let salePath = '';
    if (sale.type === 'Invoice') {
      salePath = `/org/${this.currentOrgId}/billing/payments/bills/${sale.id}/detail`;
    } else {
      salePath = `/org/${this.currentOrgId}/orders/${sale.id}/detail`;
    }
    return environment.snFrontendBaseUrl + salePath;
  }

  getMemberUrl(personaId) {
    return `${environment.snFrontendBaseUrl}/org/${this.currentOrgId}/people/${personaId}/profile`;
  }

  generateSalesSummary(summaryObj: SalesSummary): void {
    const currency = this.getCurrency(this.saleItems);
    const totalAmountInDollars = summaryObj.total_amount / 100;
    const formattedTotalAmount = this.currencyPipe.transform(totalAmountInDollars, currency, 'symbol', '1.2-2');
    this.salesSummary = `${summaryObj.total_quantity} sold • ${formattedTotalAmount} total`;
  }

  private getCurrency(saleItems: SaleItem[]): string {
    return saleItems.length ? saleItems[0].currency : 'USD';
  }
}
