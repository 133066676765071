import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { Discount } from '../models/discount.model';
import { DiscountService } from './discount.service';

const formatDiscountDate = (dateString: string): string => {
  const date = DateTime.fromFormat(dateString, 'yyyy-MM-dd');
  if (date.isValid) {
    return date.toFormat('MMMM d, yyyy');
  } else {
    return '';
  }
};

@Injectable({
  providedIn: 'root',
})
/**
 * Service responsible for generating and formatting discount summary messages.
 */
export class DiscountSummaryService {
  public discountSummary;

  constructor(
    private translateService: TranslateService,
    private currencyPipe: CurrencyPipe,
    private decimalPipe: DecimalPipe,
    private discountService: DiscountService
  ) {}

  getCompleteSummary(discount: Discount): string {
    this.createDiscountSummary(discount);
    return this.discountSummary || '';
  }

  /**
   * Creates a formatted discount summary message from a Discount object.
   * Handles both BuyX/GetY and standard discount types.
   * The resulting summary is stored in the discountSummary property.
   *
   * The summary is processed to:
   * - Trim whitespace from each sentence
   * - Remove empty sentences
   * - Join sentences with spaces
   * - Remove double periods
   *
   * @param discount - The Discount object containing the discount details
   */
  createDiscountSummary(discount: Discount): void {
    const message = discount.buyx_gety
      ? this.createBuyXGetYDiscountSummary(discount)
      : this.createStandardDiscountSummary(discount);

    this.discountSummary = message
      .map((sentence) => sentence.trim())
      .filter((sentence) => sentence !== '')
      .join(' ')
      .replace(/\.\s+\./g, '.');
  }

  /**
   * Creates an array of summary messages for a standard discount
   * @param discount - The discount object to create summary messages for
   * @returns An array of strings containing formatted summary messages in the following order:
   *          - Name message
   *          - Type specific messages (varies based on discount style)
   *          - Requirement message
   *          - Limit message
   */
  createStandardDiscountSummary(discount: Discount): string[] {
    const nameMessage = this.getNameMessage(discount);
    const requireMessage = this.getRequireMessage(discount);
    const limitMessage = this.getLimitMessage(discount);
    let typeSpecificMessages: string[] = [];

    if (discount.discount_style === 'Individual') {
      typeSpecificMessages = this.getIndividualDiscountMessages(discount);
    }
    if (discount.discount_style === 'Code') {
      typeSpecificMessages = this.getReusableDiscountMessages(discount);
    }
    if (discount.discount_style === 'Automatic') {
      typeSpecificMessages = this.getAutomaticDiscountMessages(discount);
    }

    return [nameMessage, ...typeSpecificMessages, requireMessage, limitMessage];
  }

  /**
   * Retrieves an array of formatted discount messages for an individual discounts
   * @param discount - The discount object containing code and value information
   * @returns An array of strings containing the formatted value message followed by the code message
   */
  getIndividualDiscountMessages(discount: Discount): string[] {
    const codeMessage = this.getCodeMessage(discount);
    const valueMessage = this.getValueMessage(discount);
    return [valueMessage, codeMessage];
  }

  /**
   * Returns an array of formatted discount messages for reusable/code based discounts
   * @param {Discount} discount - The discount object containing code and value information
   * @returns {string[]} An array containing the formatted code message and value message
   */
  getReusableDiscountMessages(discount: Discount): string[] {
    const codeMessage = this.getCodeMessage(discount);
    const valueMessage = this.getValueMessage(discount);
    return [codeMessage, valueMessage];
  }

  /**
   * Retrieves an array of messages describing automatic discounts.
   * @param discount - The discount object containing discount information.
   * @returns An array of strings containing formatted discount messages.
   */
  getAutomaticDiscountMessages(discount: Discount): string[] {
    const valueMessage = this.getValueMessage(discount);
    return [valueMessage];
  }

  /**
   * Creates a summary of a Buy X Get Y discount
   *
   * @param discount - The discount configuration object containing Buy X Get Y details
   * @returns An array of strings containing formatted discount summary messages:
   *          - [0] Name message
   *          - [1] Value message
   *          - [2] Details message with buy/get/scope information (HTML formatted)
   *          - [3] Restrictions message with limits and duration (HTML formatted)
   */
  createBuyXGetYDiscountSummary(discount: Discount): string[] {
    const nameMessage = this.getNameMessage(discount);
    const valueMessage = this.getValueMessage(discount);
    const requireMessage = this.getRequireMessage(discount);
    const detailsMessage = `<p>${this.getBuyMessage(discount)} ${this.getTheyGetMessage(
      discount
    )} ${this.getScopeMessage(discount)}</p>`.trim();
    const restrictionsMessage = `<p>${this.getBuyXGetYLimitMessage(discount)} ${this.getDurationMessage(
      discount
    )}</p>`.trim();

    return [nameMessage, valueMessage, detailsMessage, requireMessage, restrictionsMessage];
  }

  /**
   * Generates a message describing the "buy" part of a discount requirement
   * @param discount - The discount object containing requirement details
   * @returns A string describing the quantity of items that must be purchased,
   * or an empty string if required items or quantity are not specified
   */
  getBuyMessage(discount: Discount): string {
    if (discount.required_item_variation_ids?.length && discount.required_items_quantity) {
      const itemText = discount.required_item_variation_ids.length === 1 ? 'item' : 'items';
      return (
        `When a customer buys at least ${discount.required_items_quantity} ` +
        `out of ${discount.required_item_variation_ids.length} eligible ${itemText},`
      );
    }
    return '';
  }

  /**
   * Generates a formatted message describing what customers will receive as part of a discount.
   * @param discount - The discount object containing details about the discount offer
   * @returns A string message describing what items customers will receive and how they are discounted
   * Returns empty string if no discounted items are specified.
   */
  getTheyGetMessage(discount: Discount): string {
    let result = '';
    if (discount.discounted_item_variation_ids?.length) {
      const discountType = discount.discount_type;
      const itemText = discount.discounted_item_variation_ids.length === 1 ? 'item' : 'items';
      result = `they will receive ${discount.discounted_item_variation_ids.length} eligible discounted ${itemText}`;
      if (discountType === 'free') {
        // they will receive 4 eligible discounted items for free
        result += ' for free.';
      } else if (discountType === 'percentage') {
        // they get 30% off of 2 eligible discounted items.
        // Ensures the percentage shows as a whole number i.e. 30%
        const discountValue = discount.discount_value || '0';
        const discountValueInt = parseInt(discountValue, 10);
        const discountValuePercentage = `${discountValueInt}%`;
        result =
          `they get ${discountValuePercentage} off of ` +
          `${discount.discounted_item_variation_ids.length} eligible discounted ${itemText}`;
      } else if (discountType === 'amount') {
        // they get $100.00 off of 2 eligible discounted items
        const amount = this.currencyPipe.transform(discount.discount_value || 0);
        result = `they get ${amount} off of ${discount.discounted_item_variation_ids.length} eligible discounted ${itemText}`;
      }
    }
    return `${result}.`;
  }

  /**
   * Generates a message describing the scope of a discount
   * @param discount - The discount object containing scope and eligibility information
   * @returns A string message describing how many items the discount applies to, or an empty string
   */
  getScopeMessage(discount: Discount): string {
    if (discount.max_eligible_items > 1 && discount.discount_scope === 'Item') {
      return `This applies on up to ${discount.max_eligible_items} eligible discounted items in the order.`;
    } else if (discount.buyx_gety === false) {
      return 'for all eligible discounted items in the order.';
    }
    return '';
  }

  /**
   * States the discount type and name
   * @param {Discount} discount - The discount object containing name and discount_style properties
   * @returns {string} HTML paragraph element containing the translated message
   */
  getNameMessage(discount: Discount): string {
    let message = '';
    if (!discount.name) {
      message = this.translateService.instant(`DISCOUNTS.SUMMARY.NAME.no_name.${discount.discount_style}`);
    } else {
      message = this.translateService.instant(`DISCOUNTS.SUMMARY.NAME.name.${discount.discount_style}`, {
        name: discount.name,
      });
    }
    return `<p>${message}</p>`;
  }

  /**
   * Generates a formatted message describing the requirements for a discount to be applied.
   *
   * @param discount - The discount object containing requirement conditions
   * @returns A HTML paragraph string containing the requirement message, or an empty string if no requirements exist
   *
   * The message will indicate either:
   * - The minimum quantity required for specific sale items
   * - The minimum order total required
   */
  getRequireMessage(discount: Discount): string {
    let message = '';
    if (discount.required_item_variation_ids?.length && discount.required_items_quantity) {
      // The order must contain at least 2 of these sale items.
      const totalSaleItems = discount.required_item_variation_ids.length;
      const itemText = totalSaleItems === 1 ? 'the selected sale item' : 'these sale items';
      message = `The order must contain at least ${discount.required_items_quantity} of ${itemText}.`;
    }
    if (parseFloat(discount.minimum_sale_total) > 0) {
      // The customer must spend at least $30.00 in their order
      const minValue = this.currencyPipe.transform(discount.minimum_sale_total, discount.currency_symbol);
      message = `The customer must spend at least ${minValue} in their order.`;
    }
    return message ? `<p>${message}</p>` : '';
  }

  /**
   * Generates a localized message describing the discount code details
   *
   * @param discount - The discount object containing code information
   * @returns A localized string describing the discount code status
   *
   * For Individual discount style:
   * - Returns message with used/total code counts for existing discounts
   * - Returns message with just total count for new discounts
   * - Has special singular form when code_count is 1
   *
   * For Code discount style:
   * - Returns message with the uppercase supplied code
   */
  getCodeMessage(discount: Discount): string {
    const codeCount = discount.code_count;
    const usedCodeCount = discount.used_code_count;
    let description = '';

    if (discount.discount_style === 'Individual' && discount.code_count) {
      // if code_count is 1, then the code message should be singular
      if (discount.code_count === 1) {
        if (usedCodeCount === 1) {
          description = this.translateService.instant('DISCOUNTS.SUMMARY.CODE.individual_singular', {
            amount: codeCount,
            usedCodesAmount: usedCodeCount,
            code: 'code',
            has: 'has',
            redemptionAmount: 'No',
            codeRedeemed: 'code',
            hasRedeemed: 'has',
          });
        } else if (usedCodeCount > 1) {
          description = this.translateService.instant('DISCOUNTS.SUMMARY.CODE.individual_singular', {
            amount: codeCount,
            usedCodesAmount: usedCodeCount,
            code: 'code',
            has: 'have',
            redemptionAmount: 'No',
            codeRedeemed: 'codes',
            hasRedeemed: 'have',
          });
        } else if (usedCodeCount === 0) {
          console.log('usedCodeCount is 0');
          description = this.translateService.instant('DISCOUNTS.SUMMARY.CODE.individual_singular', {
            amount: codeCount,
            code: 'code',
            has: 'has',
            redemptionAmount: 'No',
            codeRedeemed: 'codes',
            hasRedeemed: 'have',
          });
        }
      } else if (discount.code_count > 1) {
        if (usedCodeCount === 1) {
          console.log('usedCodeCount is 1');
          description = this.translateService.instant('DISCOUNTS.SUMMARY.CODE.individual_singular', {
            amount: codeCount,
            usedCodesAmount: usedCodeCount,
            code: 'codes',
            has: 'have',
            redemptionAmount: usedCodeCount,
            codeRedeemed: 'code',
            hasRedeemed: 'has',
          });
        } else if (usedCodeCount > 1) {
          console.log('usedCodeCount is greater than 1');
          description = this.translateService.instant('DISCOUNTS.SUMMARY.CODE.individual_singular', {
            amount: codeCount,
            usedCodesAmount: usedCodeCount,
            code: 'codes',
            has: 'have',
            redemptionAmount: usedCodeCount,
            codeRedeemed: 'codes',
            hasRedeemed: 'have',
          });
        } else if (usedCodeCount === 0) {
          console.log('usedCodeCount is 0');
          description = this.translateService.instant('DISCOUNTS.SUMMARY.CODE.individual_singular', {
            amount: codeCount,
            code: 'codes',
            has: 'have',
            redemptionAmount: 'No',
            codeRedeemed: 'codes',
            hasRedeemed: 'have',
          });
        } else {
          description = this.translateService.instant('DISCOUNTS.SUMMARY.CODE.individual', {
            amount: codeCount,
            usedCodesAmount: usedCodeCount,
          });
        }
      }
    }
    if (discount.discount_style === 'Code' && discount.supplied_code) {
      const suppliedCode = discount.supplied_code.toUpperCase();
      description = this.translateService.instant('DISCOUNTS.SUMMARY.CODE.reusable', { code: suppliedCode });
    }

    return description;
  }

  /**
   * Generates a formatted message describing the discount value and its scope.
   *
   * @param discount - The discount object containing information about the discount
   * @returns A HTML string containing the formatted discount value message and scope summary
   *
   * The message includes:
   * - The discount value (amount or "Free")
   * - Maximum discount value if applicable
   * - Discount scope (Order-wide or Item-specific)
   * - Number of items the discount applies to
   * - Maximum eligible items information
   *
   * The returned string is wrapped in HTML paragraph tags.
   * All text is localized using translation keys.
   */
  getValueMessage(discount: Discount): string {
    // Get the discount value
    const discountValue = this.getValue(discount);

    if (!discountValue || !discount.discount_type) {
      return '';
    }

    let descriptionKey = 'DISCOUNTS.SUMMARY.VALUE.default'; // Default key
    let translationParams: Record<string, any> = { value: discountValue };

    if (discountValue === 'Free') {
      descriptionKey = 'DISCOUNTS.SUMMARY.VALUE.summary_free';
    } else if (discount.maximum_discount_value) {
      translationParams.max = this.currencyPipe.transform(
        this.formatCurrencyValue(discount.maximum_discount_value),
        discount.currency_symbol
      );
      descriptionKey = 'DISCOUNTS.SUMMARY.VALUE.maximum';
    } else if (discount.discount_type === 'amount') {
      translationParams.value = this.currencyPipe.transform(
        this.formatCurrencyValue(discount.discount_value),
        discount.currency_symbol
      );
    }

    // Translate value message
    let valueSummary = this.translateService.instant(descriptionKey, translationParams);

    // Ensure summary is properly formatted for non-Free discounts
    if (discountValue !== 'Free') {
      valueSummary = this.translateService.instant('DISCOUNTS.SUMMARY.VALUE.summary', { value: valueSummary });
    }

    // Determine discount scope summary
    let scopeSummary = '';
    if (discount.discount_scope) {
      if (discount.discount_scope === 'Order') {
        scopeSummary = this.translateService.instant('DISCOUNTS.SUMMARY.VALUE.unitemized');
      } else if (discount.discounted_item_variation_ids) {
        const itemCount = Array.isArray(discount.discounted_item_variation_ids)
          ? discount.discounted_item_variation_ids.length
          : (discount.discounted_item_variation_ids as unknown as string).split(',').length;

        const itemizedKey = itemCount === 1 ? 'itemized_single' : 'itemized_plural';
        scopeSummary = this.translateService.instant('DISCOUNTS.SUMMARY.VALUE.' + itemizedKey, { itemCount });
        // if buyx get y then don't include the scopeSummary
        if (discount.buyx_gety) {
          scopeSummary = '';
        }

        // Handle max eligible items
        if (discount.max_eligible_items != null) {
          const eligibilityKey =
            discount.max_eligible_items === 1
              ? 'eligible_one'
              : discount.max_eligible_items === 0
              ? 'eligible_all'
              : 'eligible_set';

          scopeSummary +=
            ' ' +
            this.translateService.instant('DISCOUNTS.SUMMARY.VALUE.' + eligibilityKey, {
              eligibleCount: discount.max_eligible_items,
            });
        }
      }
    }

    return `<p>${valueSummary} ${scopeSummary}</p>`;
  }

  /**
   * Formats the discount value based on the discount type.
   * @param discount - The discount object containing discount_type, discount_value, and currency_symbol.
   * @returns A formatted string representation of the discount value:
   *          - For 'amount': Currency formatted value with symbol
   *          - For 'percentage': Value with % symbol
   *          - For 'free': Returns "Free"
   *          - For unknown types: Returns raw discount value
   */
  getValue(discount: Discount): string {
    switch (discount.discount_type) {
      case 'amount':
        return this.currencyPipe.transform(this.formatCurrencyValue(discount.discount_value), discount.currency_symbol);
      case 'percentage':
        return `${Number(discount.discount_value)}%`;
      case 'free':
        return 'Free';
    }
    return discount.discount_value;
  }

  /**
   * Generates a message describing usage limitations for a Buy X Get Y discount
   * @param discount - The discount object containing usage limit information
   * @returns A string message indicating how many times the discount can be used
   */
  getBuyXGetYLimitMessage(discount: Discount): string {
    let message = '';
    if (discount.max_use_count > 0) {
      message = `This discount can only be used ${discount.max_use_count} times`;
    } else if (discount.max_use_count === null) {
      message = 'This discount can be used an unlimited number of times';
    }
    return `${message}`;
  }

  /**
   * Generates a duration message for a discount based on its start and end dates.
   * @param discount - The discount object containing starts_at and optional expires_at dates
   * @returns A formatted string describing the discount duration period
   */
  getDurationMessage(discount: Discount): string {
    const startsAt = formatDiscountDate(discount.starts_at);
    const expiresAt = discount.expires_at ? formatDiscountDate(discount.expires_at) : undefined;
    let message = '';
    if (expiresAt) {
      message = `between ${startsAt} and ${expiresAt}.`;
    } else {
      message = `starting on ${startsAt}.`;
    }
    return `${message}`;
  }

  /**
   * Retrieves an array of strings describing the usage limitations of a discount.
   *
   * @param discount - The discount object to analyze for usage limitations
   * @returns An array containing two strings:
   *          - First element: The "only" descriptor indicating usage restrictions
   *          - Second element: The "between" descriptor if an expiration date exists, empty string otherwise
   */
  getUseLimitDescriptors(discount: Discount): string[] {
    const onlyDescriptor = this.getOnlyDescriptor(discount);
    const betweenDescriptor = discount.expires_at
      ? this.translateService.instant('DISCOUNTS.SUMMARY.LIMIT.between')
      : '';
    return [onlyDescriptor, betweenDescriptor];
  }

  /**
   * Determines if the discount is limited and should display 'only' text
   * @param discount - The discount object to evaluate
   * @returns A translated string 'only' if the discount is limited, empty string otherwise
   */
  getOnlyDescriptor(discount: Discount): string {
    if (discount.discount_style === 'Individual' || discount.discount_style === 'Code') {
      return discount.expires_at ? this.translateService.instant('DISCOUNTS.SUMMARY.LIMIT.only') : '';
    }
    return discount.max_use === 'limited' ? this.translateService.instant('DISCOUNTS.SUMMARY.LIMIT.only') : '';
  }

  /**
   * Generates an HTML-formatted limit message for a given discount.
   * The message includes information about usage limitations, amounts, and valid dates.
   *
   * @param discount - The Discount object containing information about the discount
   * @returns A string containing an HTML paragraph with the formatted limit message
   *
   * The message is constructed using:
   * - Translation keys based on discount properties
   * - Use limit descriptors (e.g., "only", "between")
   * - Maximum use count
   * - Active dates for the discount
   * - Formatted amount message
   *
   * The final message is wrapped in a paragraph tag with class "limit-message"
   */
  getLimitMessage(discount: Discount): string {
    const translationKey = this.getTranslationKey(discount);
    const useLimitDescriptors = this.getUseLimitDescriptors(discount);
    const maxUseCount = discount.max_use_count;
    const codeDates = this.discountService.getActiveDates(discount);
    let amount = this.getAmountMessage(discount, maxUseCount, codeDates, useLimitDescriptors);

    // Construct the sentence properly
    let limitMessage = '';

    if (amount === this.translateService.instant('DISCOUNTS.SUMMARY.LIMIT.unlimited')) {
      limitMessage = `This discount can be used ${amount} times`;
    } else {
      limitMessage = `This discount can only be used ${amount} times`;
    }

    if (discount.expires_at) {
      limitMessage += ` between ${codeDates}`;
    }

    return `<p class="limit-message">${limitMessage}</p>`;
  }

  /**
   * Determines the appropriate translation key based on the discount's style and expiration status.
   * @param discount - The discount object containing style and expiration information.
   * @returns A string representing the translation key path for the discount's summary message.
   */
  getTranslationKey(discount: Discount): string {
    if (discount.discount_style === 'Individual') {
      return discount.expires_at
        ? 'DISCOUNTS.SUMMARY.LIMIT.individual.with_dates'
        : 'DISCOUNTS.SUMMARY.LIMIT.individual.no_end_date';
    }
    if (discount.discount_style === 'Code') {
      return discount.expires_at
        ? 'DISCOUNTS.SUMMARY.LIMIT.code.with_dates'
        : 'DISCOUNTS.SUMMARY.LIMIT.code.no_end_date';
    }
    return discount.expires_at
      ? 'DISCOUNTS.SUMMARY.LIMIT.reusable.with_dates'
      : 'DISCOUNTS.SUMMARY.LIMIT.reusable.n o_end_date';
  }

  getAmountMessage(discount: Discount, maxUseCount: number, codeDates: string, useLimitDescriptors: string[]): string {
    if (discount.max_use === 'unlimited' || discount.max_use_count == null) {
      return this.translateService.instant('DISCOUNTS.SUMMARY.LIMIT.unlimited'); // "an unlimited number of times"
    }
    return `${maxUseCount}`; // Return just the number, not the full sentence
  }

  /**
   * Formats a currency string value by transforming it to a decimal format and removing commas
   * @param value - The currency string to format
   * @returns The formatted currency string without commas, or empty string if decimal transformation fails
   */
  formatCurrencyValue(value: string): string {
    const decimalValue = this.decimalPipe.transform(value, '1.2-2');
    if (!decimalValue) {
      return '';
    }

    return decimalValue.includes(',') ? decimalValue.replace(/,/g, '') : decimalValue;
  }
}
