import { Component } from '@angular/core';
import { datadogRum } from '@datadog/browser-rum';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
})
export class NotFoundComponent {
  constructor() {
    datadogRum.addError('Not Found', {
      error: 'Page not found',
      url: window.location.href,
    });
  }
}
