export const environment = {
  name: 'production',
  production: true,
  staging: false,
  userServiceBaseUrl: 'https://user.sportsengine.com',
  snFrontendBaseUrl: 'https://app.sportngin.com',
  apiBaseUrl: 'https://api.sportngin.com',
  OAuthClientId: '32617a6c4892e6d3255691e9c239a93c', // update this
  OAuthResponseType: 'token',
  apiUri: 'https://api.sportngin.com',
  financialAdminLibraryUrl: 'https://se-financial-admin.ui.sportsengine.com',
  seFeFrameRouterOrigin: ['https://app.sportngin.com', 'https://org.ui.sportngin.com', 'https://org.sportngin.com'],
  seFeFrameRouterRedirectUrl: 'https://org.sportngin.com/org/:orgId/APP_PATH/*',
  launchDarklyClientId: '5f4fc406f5e88c09ee42d882',
  domainEnvironments: [
    {
      domain: 'se-store-ui.sportngin.com',
      default: true,
      urls: { api: 'https://api.sportngin.com/' },
    },
    {
      domain: 'se-store-ui.sportngin.com',
      default: false,
      urls: { api: 'https://se-api.sportsengine.com/' },
    },
  ],
};
