import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DateTime } from 'luxon';
import { SeFeFormFieldConfig } from 'se-fe-form-field';

@Injectable({
  providedIn: 'root',
})
export class DiscountFormConfigService {
  /**
   * @description Discount name text field configuration
   * @EditDiscountCard What do you want to name this discount?
   * @formControlName discountName
   */
  editDiscountNameConfig: SeFeFormFieldConfig = {
    label: 'Discount name',
    errorMessages: {
      required: 'Please enter a discount name',
      minlength: 'Please enter a min length of 2 characters',
      maxlength: 'Discount name cannot be more than 64 characters',
    },
  };

  /**
   * @description Number value text field configuration
   * @EditDiscountCard What is the value of the discount?
   * @formControlName discountValueAmount
   */
  discountValueAmountConfig: SeFeFormFieldConfig = {
    label: 'Amount',
    errorMessages: {
      required: 'Please enter a dollar amount',
      min: 'Please enter an amount of at least $1',
      max: 'Discount value exceeded the limit',
      pattern: 'Please enter a valid amount',
      percentageExceedsLimit: 'Discount percentage cannot exceed 100%',
    },
  };

  /**
   * @description Radio button configuration
   * @EditDiscountCard What is the value of the discount?
   * @formControlName discountValueType
   * @hidden
   */
  discountValueTypeOptionsConfig: SeFeFormFieldConfig = {
    label: 'Select dollars off or percent off',
    hiddenLabel: true,
    errorMessages: {
      required: 'Please select an option',
    },
  };

  /**
   * @description Radio button array of discount value type options:
   * - "$ dollars off" with value "amount"
   * - "% percent off" with value "percentage"
   * @EditDiscountCard What is the value of the discount?
   * @formControlName discountValueType
   */
  discountValueTypeOptions = [
    { label: '$ dollars off', value: 'amount' },
    { label: '% percent off', value: 'percentage' },
  ];

  /**
   * @description Number value text field configuration for a max value
   * @EditDiscountCard What is the value of the discount?
   * @formControlName discountValueMaxOff
   */
  discountValueMaxConfig: SeFeFormFieldConfig = {
    label: 'Maximum $ dollars off',
    required: true,
    errorMessages: {
      required: 'Please enter a dollar amount',
      min: 'Please enter an amount of at least $1',
      pattern: 'Please enter a valid dollar amount',
    },
  };

  /**
   * @description Radio button configuration
   * @EditDiscountCard How do you want to apply the discount?
   * @formControlName applyDiscountTypeRadios
   * @hidden
   */
  applyDiscountTypeOptionsConfig: SeFeFormFieldConfig = {
    label: 'How do you want to apply the discount?',
    hiddenLabel: true,
    errorMessages: {
      required: 'Please select an option',
    },
  };

  /**
   * @description Radio button array of applying discount options:
   * - "Discount the cart subtotal" with value "subtotal"
   * - "Discount the sale items you choose" with value "saleItems"
   * @EditDiscountCard How do you want to apply the discount?
   * @formControlName applyDiscountTypeRadios
   */
  applyDiscountTypeOptions = [
    {
      label: 'Discount the cart subtotal',
      value: 'subtotal',
    },
    {
      label: 'Discount the sale items you choose',
      value: 'saleItems',
    },
  ];

  /**
   * @description Text field for number of sale items to discount
   * @EditDiscountCard How do you want to apply the discount?
   * @formControlName saleItemsPerOrderRadios
   * @hidden
   */
  applyDiscountItemOptionsConfig: SeFeFormFieldConfig = {
    label: 'How many sale items do you want to discount per order?',
    hiddenLabel: true,
    errorMessages: {
      required: 'Please select an option',
    },
  };

  /**
   * @description Validation for sale item selection, at least one sale item must be selected if the user selects "Discount the sale items you choose"
   * @EditDiscountCard How do you want to apply the discount?
   * @formControlName saleItemsPerOrderSelectionsConfig
   */
  saleItemsPerOrderSelectionsConfig: SeFeFormFieldConfig = {
    label: 'Eligible sale items',
    hiddenLabel: true,
    errorMessages: {
      required: 'Please select at least one eligible sale item.',
    },
  };

  /**
   * @description Radio button array of choices for applying discount to sale items:
   * - "One (1) lowest price eligible sale item" with value "oneItem"
   * - "All eligible sale items in the order" with value "allItems"
   * - "A set amount" with value "setAmount"
   * @EditDiscountCard How do you want to apply the discount?
   * @formControlName saleItemsPerOrderRadios
   */
  applyDiscountItemOptions = [
    {
      label: 'One (1) lowest price eligible sale item',
      value: 'oneItem',
    },
    {
      label: 'All eligible sale items in the order',
      value: 'allItems',
    },
    {
      label: 'A set amount',
      value: 'setAmount',
    },
  ];

  /**
   * @description When a user selects "A set amount" for applying discount to sale items,
   * this text field is displayed for the user to enter a set amount.
   * Answers the question: "How many sale items do you want to discount per order?"
   * @EditDiscountCard How do you want to apply the discount?
   * @formControlName setAmountQtyField
   * @hidden
   */
  applyDiscountSetAmountConfig: SeFeFormFieldConfig = {
    label: 'Enter a set amount',
    hiddenLabel: true,
    errorMessages: {
      required: 'Please enter an amount',
      pattern: 'Please enter a valid item amount',
    },
  };

  /**
   * @description Radio button configuration
   * @EditDiscountCard What are the requirements to use this discount?
   * @formControlName discountUseRequirementsRadios
   * @hidden
   */
  discountUseRequirementsOptionsConfig: SeFeFormFieldConfig = {
    label: 'What are the requirements to use this discount?',
    hiddenLabel: true,
    errorMessages: {
      required: 'Please select an option',
    },
  };

  /**
   * @description Radio button array of choices for discount use requirements:
   * - "None" with value "noRequirements"
   * - "Spend a minimum amount ($) in an order" with value "minimumSpend"
   * - "Buy a minimum number (#) of sale items" with value "minimumItems"
   * @EditDiscountCard What are the requirements to use this discount?
   * @formControlName discountUseRequirementsRadios
   */
  discountUseRequirementsOptions = [
    {
      label: 'None',
      value: 'noRequirements',
    },
    {
      label: 'Spend a minimum amount ($) in an order',
      value: 'minimumSpend',
    },
    {
      label: 'Buy a minimum number (#) of sale items',
      value: 'minimumItems',
    },
  ];

  /**
   * @description Validation for sale item selection, at least one sale item must be selected if the user selects "Buy a minimum number (#) of sale items"
   * @EditDiscountCard What are the requirements to use this discount?
   * @formControlName saleItemsRequiredSelectionsConfig
   */
  saleItemsRequiredSelectionsConfig: SeFeFormFieldConfig = {
    label: 'Required sale items',
    hiddenLabel: true,
    errorMessages: {
      required: 'Please select at least one required sale item.',
    },
  };

  /**
   * @description Text field configuration for minimum spend amount
   * @EditDiscountCard What are the requirements to use this discount?
   * @formControlName minimumSpendAmountField
   * @hidden
   */
  minimumSpendAmountFieldConfig: SeFeFormFieldConfig = {
    label: 'Minimum spend amount',
    hiddenLabel: true,
    errorMessages: {
      required: 'Please enter an amount',
      pattern: 'Please enter a valid dollar amount',
      min: 'Please enter an amount of at least $1',
    },
  };

  /**
   * @description Text field configuration for minimum quantity
   * @EditDiscountCard What are the requirements to use this discount?
   * @formControlName minimumItemsQtyField
   * @hidden
   */
  minimumItemsQtyFieldConfig: SeFeFormFieldConfig = {
    label: 'Minimum quantity',
    hiddenLabel: true,
    errorMessages: {
      required: 'Please enter an amount',
      pattern: 'Please enter a valid item amount',
    },
  };

  /**
   * @description Radio button configuration
   * @EditDiscountCard How many orders can use this discount?
   * @formControlName discountMaxUseRadios
   * @hidden
   */
  discountMaxUseOptionsConfig: SeFeFormFieldConfig = {
    label: 'How many orders can use this discount?',
    hiddenLabel: true,
    errorMessages: {
      required: 'Please select an option',
    },
  };

  /**
   * @description Radio button array of choices for discount max use:
   * - "Unlimited" with value "unlimited"
   * - "A maximum of number of times" with value "limited"
   * @EditDiscountCard How many orders can use this discount?
   * @formControlName discountMaxUseRadios
   */
  discountMaxUseOptions = [
    {
      label: 'Unlimited',
      value: 'unlimited',
    },
    {
      label: 'A maximum of number of times',
      value: 'limited',
    },
  ];

  /**
   * @description Text field configuration for maximum use count
   * @EditDiscountCard How many orders can use this discount?
   * @formControlName discountMaxUseCount
   * @hidden
   */
  discountMaxUseCountFieldConfig: SeFeFormFieldConfig = {
    label: 'Maximum of number of times',
    hiddenLabel: true,
    errorMessages: {
      required: 'Please enter a maximum amount',
      pattern: 'Please enter a positive number',
      min: 'Please enter a number greater than or equal to the number already redeemed',
    },
  };

  /**
   * @description Text field configuration for start date
   * @EditDiscountCard When will this discount be available?
   * @formControlName fromDate
   * @hidden
   */
  fromDateConfig: SeFeFormFieldConfig = {
    label: 'Start date',
    hiddenLabel: true,
    errorMessages: {
      required: 'Please enter a start date',
      dateInvalid: 'Please enter a valid start date',
      dateOutOfRange: 'Please select a future date or today',
    },
  };

  /**
   * @description Text field configuration for end date
   * @EditDiscountCard When will this discount be available?
   * @formControlName toDate
   * @hidden
   */
  toDateConfig: SeFeFormFieldConfig = {
    label: 'End date',
    hiddenLabel: true,
    errorMessages: {
      required: 'Please enter an end date',
      dateInvalid: 'Please enter a valid end date',
      dateOutOfRange: 'End date must be on or after the start date',
    },
  };

  /**
   * @description Text field configuration for number of individual codes
   * @EditDiscountCard How many individual codes do you need?
   * @formControlName discountIndividualCodes
   */
  discountIndividualCodesFieldConfig: SeFeFormFieldConfig = {
    label: 'Number of individual codes',
    hiddenLabel: true,
    errorMessages: {
      pattern: 'Please enter a whole number greater than 0',
    },
  };

  constructor() {}

  /**
   * Validates the date range between two form controls.
   *
   * @param fromDateKey - The key of the form control representing the start date.
   * @param toDateKey - The key of the form control representing the end date.
   * @returns A validator function that checks if the end date is greater than the start date.
   */
  dateRangeValidator(fromDateKey: string, toDateKey: string): ValidatorFn {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      const fromDate = formGroup.get(fromDateKey).value;
      const toDate = formGroup.get(toDateKey).value;

      if (fromDate && !toDate) {
        return null;
      }

      const isValid = new Date(toDate) >= new Date(fromDate);
      return isValid ? null : { dateRangeInvalid: true };
    };
  }

  /**
   * Checks if the selected date is not in the past.
   *
   * @param control - The form control to validate.
   * @returns An object containing a validation error if the date is in the past, or null if the date is valid.
   */
  static notInPastValidator(control: AbstractControl): ValidationErrors | null {
    if (!control.value || control.pristine) {
      // Skip validation if pristine (not modified)
      return null;
    }
    const selectedDate = DateTime.fromISO(control.value).startOf('day');
    const today = DateTime.now().startOf('day');
    return selectedDate < today ? { dateOutOfRange: true } : null;
  }

  /**
   * Retrieves the configuration object associated with the given key.
   *
   * @param key - The key for which the configuration is to be retrieved.
   * @returns The configuration object corresponding to the provided key.
   *
   * @remarks
   * The method uses a predefined map of keys to configuration objects.
   * If the provided key does not exist in the map, the method returns `undefined`.
   */
  private getConfigForKey(key: string): any {
    const configMap: { [key: string]: any } = {
      discountName: this.editDiscountNameConfig,
      discountValueType: this.discountValueTypeOptionsConfig,
      discountValueMaxOff: this.discountValueMaxConfig,
      applyDiscountTypeRadios: this.applyDiscountTypeOptionsConfig,
      saleItemsPerOrderRadios: this.applyDiscountItemOptionsConfig,
      setAmountQtyField: this.applyDiscountSetAmountConfig,
      discountUseRequirementsRadios: this.discountUseRequirementsOptionsConfig,
      minimumSpendAmountField: this.minimumSpendAmountFieldConfig,
      minimumItemsQtyField: this.minimumItemsQtyFieldConfig,
      discountValueAmount: this.discountValueAmountConfig,
      fromDate: this.fromDateConfig,
      toDate: this.toDateConfig,
      saleItemsPerOrderSelections: this.saleItemsPerOrderSelectionsConfig,
      saleItemsRequiredSelections: this.saleItemsRequiredSelectionsConfig,
      discountIndividualCodes: this.discountIndividualCodesFieldConfig,
      discountMaxUseRadios: this.discountMaxUseOptionsConfig,
      discountMaxUseCount: this.discountMaxUseCountFieldConfig,
    };
    return configMap[key];
  }

  /**
   * Retrieves an array of error messages from a given form control.
   *
   * @param form - The form control to extract errors from. This can be an instance of `AbstractControl`.
   * @returns An array of error messages as strings.
   *
   * This method iterates over the controls of a `FormGroup` and collects error messages based on the configuration
   * provided for each control. If a specific error message is configured for a control, it uses that message;
   * otherwise, it generates a default error message.
   */
  getFormErrors(form: AbstractControl): string[] {
    const errors: string[] = [];
    if (form instanceof FormGroup) {
      Object.keys(form.controls).forEach((key) => {
        const control = form.get(key);
        if (control && control.errors) {
          Object.keys(control.errors).forEach((keyError) => {
            let errorMsg = '';
            const config = this.getConfigForKey(key);

            if (config && config.errorMessages && config.errorMessages[keyError]) {
              errorMsg = config.errorMessages[keyError];
            } else {
              errorMsg = `${key} has an error: ${keyError}`;
            }

            errors.push(errorMsg);
          });
        }
      });
    }
    return errors;
  }

  /**
   * Retrieves an array with specific message for custom validator
   *
   * @param form - The form control to extract errors from. This can be an instance of `AbstractControl`.
   * @param fromDateKey - The key of the form control representing the start date.
   * @param toDateKey - The key of the form control representing the end date.
   * @returns An array of error messages as strings.
   *
   * This method check the value of the dates and returns an error message if end date is past start date
   * We have to get the message because of the custom validator
   */
  getDateRangeErrorMessage(form: AbstractControl, fromDateKey: string, toDateKey: string) {
    const fromDate = form.get(fromDateKey).value;
    const toDate = form.get(toDateKey).value;

    const fromDateObj = new Date(fromDate);
    const toDateObj = new Date(toDate);

    if (fromDateObj > toDateObj) {
      return ['End date must be on or after the start date'];
    }
    return [];
  }
}
