import { Component } from '@angular/core';
import { datadogRum } from '@datadog/browser-rum';

@Component({
  template: `
    <div class="pl-pane">
      <se-fe-empty-state [seFeDataTitle]="title">
        <se-fe-icon seFeEmptyStateIcon seFeDataName="clipboard_thin" seFeDataSize="950"></se-fe-icon>
        {{ message }}
      </se-fe-empty-state>
    </div>
  `,
})
export class UnknownErrorComponent {
  title = "Whoa, that's not in the playbook";
  message = 'Something went wrong while trying to load the page. Please try again.';

  constructor() {
    datadogRum.addError('Unknown Error', {
      error: 'Unknown error',
      url: window.location.href,
    });
  }
}
