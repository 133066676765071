<se-fe-page SeFeDataWidth="full" [seFeDataLoaded]="!discountDataLoading">
  <se-fe-header
    *ngIf="!discountDataLoading"
    seFePageHeader
    [seFeDataTitle]="'DISCOUNTS.EDIT_DISCOUNT.title' | translate : { name: discount.name }"
    size="750">
    <se-fe-icon-button
      data-dd-action-name="Close Discount"
      seFeHeaderActions
      [routerLink]="'/discounts/' + discountId + '/detail'">
      <se-fe-icon
        seFeDataName="remove"
        seFeDataSize="700"
        [seFeDataAriaLabel]="'DISCOUNTS.EDIT_DISCOUNT.close' | translate"></se-fe-icon>
    </se-fe-icon-button>
  </se-fe-header>

  <div seFePageContent>
    <app-discount-form *ngIf="!discountDataLoading" [formMode]="'edit'" [discount]="discount"></app-discount-form>
  </div>
</se-fe-page>
