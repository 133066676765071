import { Component, ViewEncapsulation } from '@angular/core';
import { DatadogService } from './services/datadog.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
  constructor(private datadogService: DatadogService) {
    this.datadogService.initialize();
  }
}
