<se-fe-page SeFeDataWidth="full">
  <se-fe-header seFePageHeader seFeDataTitle="Edit {{ this.editDiscountForm.get('discountName').value }} " size="750">
    <se-fe-icon-button seFeHeaderActions [routerLink]="'/discounts/' + this.discountID + '/detail'">
      <se-fe-icon seFeDataName="remove" seFeDataSize="700" seFeDataAriaLabel="Close"></se-fe-icon>
    </se-fe-icon-button>
  </se-fe-header>
  <div seFePageContent>
    <se-fe-grid seFeGridOpts="center">
      <se-fe-grid-cell seFeGridOpts="1/1@mobile 1/2@tablet 1/3@desktop">
        <se-fe-grid seFeGridOpts>
          <se-fe-grid-cell seFeGridOpts>
            <se-fe-card>
              <se-fe-card-header seFeDataTitle="Summary"></se-fe-card-header>
              <se-fe-card-content>
                <se-fe-grid seFeGridOpts>
                  <se-fe-grid-cell seFeGridOpts>
                    <div class="summary-text" [innerHtml]="getCompleteSummary()"></div>
                  </se-fe-grid-cell>
                </se-fe-grid>
              </se-fe-card-content>
            </se-fe-card>
          </se-fe-grid-cell>
        </se-fe-grid>
      </se-fe-grid-cell>
      <se-fe-grid-cell seFeGridOpts="1/1@mobile 1/2@tablet 2/3@desktop">
        <div id="submission_errors" [hidden]="!hasSubmissionErrors" aria-label="Submission errors">
          <se-fe-alert [seFeDataType]="'error'" seFeDataHeader="Form Submission Error">
            <ul>
              <li *ngFor="let error of submissionErrors">{{ error }}</li>
            </ul>
          </se-fe-alert>
        </div>
        <form [formGroup]="editDiscountForm" (ngSubmit)="editDiscountSubmit()">
          <!-- What type of discount do you want to create? -->
          <se-fe-grid seFeGridOpts>
            <se-fe-grid-cell seFeGridOpts>
              <se-fe-card>
                <se-fe-card-header
                  seFeDataTitle="What type of discount do you want to create?"
                  class="edit-name-header"></se-fe-card-header>
                <se-fe-card-content>
                  <se-fe-grid seFeGridOpts>
                    <se-fe-grid-cell seFeGridOpts>
                      <div seFeHelp>
                        {{ uneditableDiscounts[(discount$ | async)?.discount_style] }}
                      </div>
                    </se-fe-grid-cell>
                  </se-fe-grid>
                </se-fe-card-content>
              </se-fe-card>
            </se-fe-grid-cell>
          </se-fe-grid>

          <!-- What do you want to name this discount? -->
          <se-fe-grid seFeGridOpts>
            <se-fe-grid-cell seFeGridOpts>
              <se-fe-card>
                <se-fe-card-header
                  seFeDataTitle="What do you want to name this discount?"
                  class="edit-name-header"></se-fe-card-header>
                <se-fe-card-content>
                  <se-fe-grid seFeGridOpts>
                    <se-fe-grid-cell seFeGridOpts>
                      <se-fe-form-field [seFeDataConfig]="editDiscountNameConfig">
                        <se-fe-form-field-text
                          formControlName="discountName"
                          seFeDataAutoComplete="off"></se-fe-form-field-text>
                        <div seFeHelp>This name will appear in the checkout, on receipts, and in reports.</div>
                      </se-fe-form-field>
                    </se-fe-grid-cell>
                  </se-fe-grid>
                </se-fe-card-content>
              </se-fe-card>
            </se-fe-grid-cell>
          </se-fe-grid>

          <!-- Customer Buys -->
          <ng-container *ngIf="discount?.buyx_gety">
            <se-fe-grid seFeGridOpts>
              <se-fe-grid-cell seFeGridOpts>
                <se-fe-card class="value-cards">
                  <se-fe-card-header seFeDataTitle="Customer Buys" class="edit-value-header"></se-fe-card-header>
                  <se-fe-card-content>
                    <se-fe-grid seFeGridOpts>
                      <se-fe-grid-cell seFeGridOpts>
                        <app-customer-buys [currentOrgId]="currentOrgId"></app-customer-buys>
                      </se-fe-grid-cell>
                    </se-fe-grid>
                  </se-fe-card-content>
                </se-fe-card>
              </se-fe-grid-cell>
            </se-fe-grid>
          </ng-container>

          <!-- Customer Gets -->
          <ng-container *ngIf="discount?.buyx_gety">
            <se-fe-grid seFeGridOpts>
              <se-fe-grid-cell seFeGridOpts>
                <se-fe-card class="value-cards">
                  <se-fe-card-header seFeDataTitle="Customer Gets" class="edit-value-header"></se-fe-card-header>
                  <se-fe-card-content>
                    <se-fe-grid seFeGridOpts>
                      <se-fe-grid-cell seFeGridOpts>
                        <app-customer-gets [currentOrgId]="currentOrgId"></app-customer-gets>
                      </se-fe-grid-cell>
                    </se-fe-grid>
                  </se-fe-card-content>
                </se-fe-card>
              </se-fe-grid-cell>
            </se-fe-grid>
          </ng-container>

          <!-- What is the value of the discount? -->
          <se-fe-grid seFeGridOpts *ngIf="!discount?.buyx_gety">
            <se-fe-grid-cell seFeGridOpts>
              <se-fe-card class="value-cards">
                <se-fe-card-header
                  seFeDataTitle="What is the value of the discount?"
                  class="edit-value-header"></se-fe-card-header>
                <se-fe-card-content>
                  <se-fe-grid seFeGridOpts>
                    <se-fe-grid-cell seFeGridOpts>
                      <se-fe-form-field [seFeDataConfig]="discountValueAmountConfig">
                        <se-fe-form-field-text
                          formControlName="discountValueAmount"
                          seFeDataType="number"
                          seFeDataAutoComplete="off"></se-fe-form-field-text>
                      </se-fe-form-field>

                      <se-fe-form-field [seFeDataConfig]="discountValueTypeOptionsConfig">
                        <se-fe-radios
                          formControlName="discountValueType"
                          [seFeDataOptions]="discountValueTypeOptions"></se-fe-radios>
                      </se-fe-form-field>

                      <se-fe-button
                        *ngIf="discountValueMaxButton"
                        seFeDataEmphasis="low"
                        (click)="discountValueMaxToggle()"
                        [seFeDataSnug]="true">
                        Maximum $ dollars off
                      </se-fe-button>
                      <div *ngIf="showDiscountValueMaxField" class="discountValueMaxField">
                        <se-fe-form-field [seFeDataConfig]="discountValueMaxConfig">
                          <se-fe-form-field-text
                            formControlName="discountValueMaxOff"
                            seFeDataType="number"
                            seFeDataAutoComplete="off"></se-fe-form-field-text>
                        </se-fe-form-field>
                        <div *ngIf="editDiscountForm.get('discountValueType').value === 'percentage'">
                          {{ editDiscountForm.get('discountValueAmount').value }}% off, up to a maximum of ${{
                            editDiscountForm.get('discountValueMaxOff').value
                          }}
                        </div>
                        <se-fe-button seFeDataEmphasis="low" (click)="discountValueMaxToggle()" [seFeDataSnug]="true">
                          Remove maximum $ dollars off
                        </se-fe-button>
                      </div>
                    </se-fe-grid-cell>
                  </se-fe-grid>
                </se-fe-card-content>
              </se-fe-card>
            </se-fe-grid-cell>
          </se-fe-grid>

          <!-- How do you want to apply the discount? -->
          <se-fe-grid seFeGridOpts *ngIf="!discount?.buyx_gety">
            <se-fe-grid-cell seFeGridOpts>
              <se-fe-card>
                <se-fe-card-header
                  seFeDataTitle="How do you want to apply the discount?"
                  class="edit-apply-to-header"></se-fe-card-header>
                <se-fe-card-content>
                  <se-fe-grid seFeGridOpts>
                    <se-fe-grid-cell seFeGridOpts>
                      <!-- How do you want to apply the discount? -->
                      <se-fe-form-field [seFeDataConfig]="applyDiscountTypeOptionsConfig">
                        <p *ngIf="isDiscountScopeSubtotal">Discount the cart subtotal</p>
                      </se-fe-form-field>
                      <div *ngIf="!isDiscountScopeSubtotal">
                        <p>Discount the sale items you choose</p>
                        <div class="applyDiscountSaleItem" *ngIf="applyDiscountSaleItemButton">
                          <se-fe-form-control-label
                            seFeDataLabel="Which sale items do you want to discount?"></se-fe-form-control-label>

                          <app-sale-item-modal
                            id="item-selector"
                            class="sale-item-modal"
                            [organizationId]="currentOrgId"
                            [selectedItemIds]="editDiscountForm.get('saleItemsPerOrderSelections').value"
                            (selectionSaved)="updateDiscountedItemsTable($event)"></app-sale-item-modal>
                          <p *ngIf="editDiscountForm.get('saleItemsPerOrderSelections').errors?.required" class="error">
                            Please select at least one sale item for this discount.
                          </p>

                          <se-fe-form-field [seFeDataConfig]="applyDiscountItemOptionsConfig">
                            <se-fe-radios
                              formControlName="saleItemsPerOrderRadios"
                              [seFeDataOptions]="applyDiscountItemOptions"></se-fe-radios>
                          </se-fe-form-field>

                          <!-- if setAmount is selected -->
                          <div *ngIf="applyDiscountSetAmountField">
                            <se-fe-form-control-label
                              seFeDataLabel="How many sale items do you want to discount per order?"
                              seFeDataFor="setAmountQtyField"></se-fe-form-control-label>
                            <se-fe-form-field [seFeDataConfig]="applyDiscountSetAmountConfig">
                              <se-fe-form-field-text
                                formControlName="setAmountQtyField"
                                seFeDataType="number"
                                seFeDataAutoComplete="off"></se-fe-form-field-text>
                            </se-fe-form-field>
                          </div>
                        </div>
                      </div>
                    </se-fe-grid-cell>
                  </se-fe-grid>
                </se-fe-card-content>
              </se-fe-card>
            </se-fe-grid-cell>
          </se-fe-grid>

          <!-- What are the requirements to use this discount? -->
          <se-fe-grid seFeGridOpts *ngIf="!discount?.buyx_gety">
            <se-fe-grid-cell seFeGridOpts>
              <se-fe-card>
                <se-fe-card-header
                  seFeDataTitle="What are the requirements to use this discount?"
                  class="edit-apply-to-header"></se-fe-card-header>
                <se-fe-card-content>
                  <se-fe-grid seFeGridOpts>
                    <se-fe-grid-cell seFeGridOpts>
                      <se-fe-form-field [seFeDataConfig]="discountUseRequirementsOptionsConfig">
                        <se-fe-radios
                          formControlName="discountUseRequirementsRadios"
                          [seFeDataOptions]="discountUseRequirementsOptions">
                          <!-- Custom template for radio buttons -->
                          <ng-template let-option let-index="index" let-checked="checked">
                            <div>
                              {{ option.label }}
                            </div>
                            <!-- Conditionally render the minimumSpendAmountField after "Spend a minimum amount ($)" option -->
                            <div *ngIf="option.value === 'minimumSpend' && checked && minimumSpendAmountFieldVisible">
                              <se-fe-form-field [seFeDataConfig]="minimumSpendAmountFieldConfig">
                                <se-fe-form-field-text
                                  formControlName="minimumSpendAmountField"
                                  seFeDataType="number"
                                  seFeDataAutoComplete="off"></se-fe-form-field-text>
                              </se-fe-form-field>
                            </div>
                          </ng-template>
                        </se-fe-radios>
                      </se-fe-form-field>

                      <div *ngIf="minItemsFieldVisible" class="minItemsFieldVisible">
                        <se-fe-form-control-label
                          seFeDataLabel="Which sale items are required?"
                          seFeDataFor="saleItemsRequiredSelections"></se-fe-form-control-label>

                        <app-sale-item-modal
                          id="item-selector"
                          class="sale-item-modal"
                          [organizationId]="currentOrgId"
                          [selectedItemIds]="editDiscountForm.get('saleItemsRequiredSelections').value"
                          (selectionSaved)="updateRequiredItemsTable($event)"></app-sale-item-modal>
                        <p *ngIf="editDiscountForm.get('saleItemsRequiredSelections').errors?.required" class="error">
                          Please select at least one required item.
                        </p>

                        <se-fe-form-control-label
                          seFeDataLabel="Minimum quantity"
                          seFeDataFor="minimumItemsQtyField"></se-fe-form-control-label>
                        <se-fe-form-field [seFeDataConfig]="minimumItemsQtyFieldConfig">
                          <se-fe-form-field-text
                            formControlName="minimumItemsQtyField"
                            seFeDataType="number"
                            seFeDataAutoComplete="off"></se-fe-form-field-text>
                        </se-fe-form-field>
                      </div>
                    </se-fe-grid-cell>
                  </se-fe-grid>
                </se-fe-card-content>
              </se-fe-card>
            </se-fe-grid-cell>
          </se-fe-grid>

          <!-- How many orders can use this discount? -->
          <!-- Only shows for reusable and automatic discounts -->
          <se-fe-grid seFeGridOpts *ngIf="showMaxUsageSection">
            <se-fe-grid-cell seFeGridOpts>
              <se-fe-card>
                <se-fe-card-header
                  seFeDataTitle="How many orders can use this discount?"
                  class="edit-apply-to-header"></se-fe-card-header>
                <se-fe-card-content>
                  <se-fe-grid seFeGridOpts>
                    <se-fe-grid-cell seFeGridOpts>
                      <se-fe-form-field [seFeDataConfig]="discountMaxUseOptionsConfig">
                        <se-fe-radios formControlName="discountMaxUseRadios" [seFeDataOptions]="discountMaxUseOptions">
                          <!-- Custom template for radio buttons -->
                          <ng-template let-option let-index="index" let-checked="checked">
                            <div>
                              {{ option.label }}
                            </div>
                            <!-- Conditionally render the discountMaxUseCountField after "A maximum of number of times" option -->
                            <div *ngIf="option.value === 'limited' && checked">
                              <se-fe-form-field [seFeDataConfig]="discountMaxUseCountFieldConfig">
                                <p>{{ discountMaxUseRedeemed() }} • {{ discountMaxUseRemaining() }}</p>
                                <se-fe-form-field-text
                                  formControlName="discountMaxUseCount"
                                  seFeDataType="number"
                                  seFeDataAutoComplete="off"></se-fe-form-field-text>
                              </se-fe-form-field>
                            </div>
                          </ng-template>
                        </se-fe-radios>
                      </se-fe-form-field>
                    </se-fe-grid-cell>
                  </se-fe-grid>
                </se-fe-card-content>
              </se-fe-card>
            </se-fe-grid-cell>
          </se-fe-grid>

          <!-- When will this discount be available? -->
          <se-fe-grid seFeGridOpts>
            <se-fe-grid-cell seFeGridOpts>
              <se-fe-card>
                <se-fe-card-header
                  seFeDataTitle="When will this discount be available?"
                  class="edit-apply-to-header"></se-fe-card-header>
                <se-fe-card-content>
                  <se-fe-grid seFeGridOpts>
                    <se-fe-grid-cell seFeGridOpts>
                      <se-fe-form-field [seFeDataConfig]="discountUseRequirementsOptionsConfig"></se-fe-form-field>
                      <div class="date-picker">
                        <se-fe-datepicker
                          seFeDataLabel="Start Date"
                          seFeDataHelpText="MM/DD/YYYY"
                          [seFeDataDateRangeIcon]="true"
                          formControlName="fromDate"
                          [seFeDataYearRange]="{ future: 5, past: 0 }"
                          [seFeDataExcludeDates]="excludeDatesAfterEnd()"
                          [seFeDataMessages]="{
                            required: 'Please enter a start date',
                            dateInvalid: 'Please enter a valid start date',
                            dateOutOfRange: 'Please select a future date or today',
                          }"></se-fe-datepicker>

                        <se-fe-datepicker
                          seFeDataLabel="End Date"
                          seFeDataHelpText="MM/DD/YYYY"
                          [seFeDataDateRangeIcon]="true"
                          formControlName="toDate"
                          [seFeDataYearRange]="{ future: 5, past: 0 }"
                          [seFeDataExcludeDates]="excludeDatesBeforeStart()"
                          [seFeDataMessages]="{
                            required: 'Please enter an end date',
                            dateInvalid: 'Please enter a valid end date',
                            dateOutOfRange: 'End date must be on or after the start date'
                          }"></se-fe-datepicker>
                      </div>
                    </se-fe-grid-cell>
                  </se-fe-grid>
                </se-fe-card-content>
                <!-- dont show this button if the discount is an individual discount -->
                <se-fe-card-footer seFeDataBorder="full" *ngIf="!showIndividualCodesSection">
                  <div class="edit-discount-submit">
                    <se-fe-button
                      seFeDataType="submit"
                      seFeDataEmphasis="high"
                      [seFeDataIsLoading]="saving"
                      seFeDataLoadingLabel="Saving">
                      Save
                    </se-fe-button>
                  </div>
                </se-fe-card-footer>
              </se-fe-card>
            </se-fe-grid-cell>
          </se-fe-grid>

          <!--How many individual codes do you need?  -->
          <!-- Only shows for individual discounts -->
          <se-fe-grid seFeGridOpts *ngIf="showIndividualCodesSection">
            <se-fe-grid-cell seFeGridOpts>
              <se-fe-card>
                <se-fe-card-header
                  seFeDataTitle="How many individual codes do you need?"
                  class="edit-apply-to-header"></se-fe-card-header>
                <se-fe-card-content>
                  <se-fe-grid seFeGridOpts>
                    <se-fe-grid-cell seFeGridOpts>
                      <p>
                        {{ discountMaxUseTotal() }} •
                        {{ discountCodesAvailable() }}
                      </p>
                      <br />
                      <se-fe-form-field
                        [seFeDataConfig]="discountIndividualCodesFieldConfig"
                        [seFeDataConfig]="{ label: 'Additional codes' }">
                        <se-fe-form-field-text
                          formControlName="discountIndividualCodeCount"
                          seFeDataType="number"
                          seFeDataAutoComplete="off"></se-fe-form-field-text>
                        <div seFeHelp>Each code can only be redeemed once.</div>
                      </se-fe-form-field>
                    </se-fe-grid-cell>
                  </se-fe-grid>
                </se-fe-card-content>
                <se-fe-card-footer seFeDataBorder="full">
                  <div class="edit-discount-submit">
                    <se-fe-button
                      data-dd-action-name="Save Edit Discount V2"
                      seFeDataType="submit"
                      seFeDataEmphasis="high"
                      [seFeDataIsLoading]="saving"
                      seFeDataLoadingLabel="Saving">
                      Save
                    </se-fe-button>
                  </div>
                </se-fe-card-footer>
              </se-fe-card>
            </se-fe-grid-cell>
          </se-fe-grid>
        </form>
      </se-fe-grid-cell>
    </se-fe-grid>
  </div>
</se-fe-page>
