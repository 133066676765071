import { Injectable, isDevMode } from '@angular/core';
import { datadogRum } from '@datadog/browser-rum';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DatadogService {
  currentEnv = environment.name;

  constructor() {}

  initialize(): void {
    if (isDevMode() || this.currentEnv === 'development') {
      return;
    }

    datadogRum.init({
      applicationId: '1296ab3c-f9e5-4bb8-8a44-2e0fdec140eb',
      clientToken: 'pub13af3461db02780fe7e2c6d061ac6ab2',
      site: 'datadoghq.com',
      service: 'store-ui',
      env: this.currentEnv,
      version: '1.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'allow', // allowing user input to be seen as there's no sensitive data
      trackSessionAcrossSubdomains: true,
    });

    datadogRum.startSessionReplayRecording();

    // hiding for now to see if we want to use just RUM logging
    // datadogLogs.init({
    //   clientToken: 'pub13af3461db02780fe7e2c6d061ac6ab2',
    //   site: 'datadoghq.com',
    //   service: 'store-ui',
    //   env: this.currentEnv,
    //   forwardErrorsToLogs: true,
    //   sessionSampleRate: 100,
    //   trackSessionAcrossSubdomains: true,
    // });
  }
}
