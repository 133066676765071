<se-fe-page seFeDataBackLink="../.." seFeDataBackText="Discounts" [seFeDataLoaded]="true" SeFeDataWidth="full">
  <se-fe-header
    *ngIf="discountData"
    seFePageHeader
    [seFeDataTitle]="discountData.name"
    [seFeDataSubtitle]="headerSubtitles"
    size="750">
    <div seFeHeaderTitleExtra>
      <se-fe-chip
        [seFeDataText]="discountService.getDiscountStatus(discountData) | titlecase"
        [seFeDataStyle]="
          ('DISCOUNTS.STATUS.' + discountService.getDiscountStatus(discountData) | translate) + '-emphasis'
        "
        seFeDataIconLeft="dot_fill"></se-fe-chip>
    </div>
    <!-- <div seFeHeaderActions>
      <button class="se-high-button">
        {{ 'DISCOUNTS.DETAIL_VIEW.action' | translate }}
      </button>
    </div> -->
  </se-fe-header>

  <div seFePageContent>
    <se-fe-grid seFeGridOpts>
      <!-- <se-fe-grid-cell seFeGridOpts="2/3">
        <se-fe-card>
          <se-fe-card-header
            [seFeDataTitle]="'DISCOUNTS.DETAIL_VIEW.PERFORMANCE.title' | translate"
            seFeDataHeavyBorder="true">
            <se-fe-inline-list seFeCardHeaderActions>
              <se-fe-inline-list-item *ngFor="let performance of discountPerformance">
                {{ performance }}
              </se-fe-inline-list-item>
            </se-fe-inline-list>
          </se-fe-card-header>

          <app-discount-chart
            [discountData]="[discountData]"
            [fullChart]="false"
            (discountRedeemed)="calculatePerformance($event)"></app-discount-chart>
        </se-fe-card>
      </se-fe-grid-cell> -->

      <se-fe-grid-cell seFeGridOpts="1/2@desktop first@desktop 1/2@tablet first@tablet 1/1@mobile last@mobile">
        <se-fe-card>
          <se-fe-card-header
            [seFeDataTitle]="'DISCOUNTS.DETAIL_VIEW.REDEMPTION.title' | translate"
            seFeDataHeavyBorder="true">
            <div [hidden]="!hideEmptyState" seFeCardHeaderActions>
              <button class="se-low-button" (click)="exportAsCsv()">
                {{ 'DISCOUNTS.DETAIL_VIEW.REDEMPTION.action' | translate }}
              </button>
            </div>
          </se-fe-card-header>

          <se-fe-card-content class="redemption" [ngClass]="{ showEmptyState: !hideEmptyState }">
            <div [hidden]="hideEmptyState">
              <se-fe-empty-state
                [seFeDataTitle]="'DISCOUNTS.DETAIL_VIEW.REDEMPTION.EMPTY_STATE.title' | translate"
                [seFeDataSize]="500">
                {{ 'DISCOUNTS.DETAIL_VIEW.REDEMPTION.EMPTY_STATE.description' | translate }}
                <!-- <se-fe-button seFeEmptyStateAction [seFeDataEmphasis]="'high'" [routerLink]="'share'">
                  {{ 'DISCOUNTS.DETAIL_VIEW.REDEMPTION.EMPTY_STATE.action' | translate }}
                </se-fe-button> -->
              </se-fe-empty-state>
            </div>

            <div class="table-container" [hidden]="!hideEmptyState">
              <se-fe-toolbar [seFeDataQuery]="searchTerm" (seFeQueryChange)="search($event)"></se-fe-toolbar>

              <se-fe-spinner
                *ngIf="discountDataLoading"
                [seFeDataSize]="900"
                [seFeDataCenter]="true"
                class="se-fe-spinner--discountDataLoading"></se-fe-spinner>

              <div [hidden]="discountCodeData.length > 0 || discountDataLoading">
                <se-fe-card-content>
                  <se-fe-empty-state
                    [seFeDataTitle]="'DISCOUNTS.DETAIL_VIEW.REDEMPTION.SEARCH.EMPTY_STATE.title' | translate"
                    [seFeDataSize]="500">
                    {{ 'DISCOUNTS.DETAIL_VIEW.REDEMPTION.SEARCH.EMPTY_STATE.description' | translate }}
                  </se-fe-empty-state>
                </se-fe-card-content>
              </div>

              <div [hidden]="!discountCodeData.length || discountDataLoading">
                <table seFeTable seFeTableSort [seFeDataFixedLayout]="false">
                  <!-- <ng-container seFeColumnDef="created_by">
                    <th seFeDataRef="created_by" seFeTableSortHeader seFeHeaderCell *seFeHeaderCellDef>
                      {{ 'DISCOUNTS.DETAIL_VIEW.COLUMNS.created_by' | translate }}
                    </th>
                    <td seFeCell *seFeCellDef="let row">{{ row.created_by }}</td>
                  </ng-container>

                  <ng-container seFeColumnDef="sent_by">
                    <th seFeDataRef="sent_by" seFeTableSortHeader seFeHeaderCell *seFeHeaderCellDef>
                      {{ 'DISCOUNTS.DETAIL_VIEW.COLUMNS.sent_by' | translate }}
                    </th>
                    <td seFeCell *seFeCellDef="let row">{{ row.sent_by }}</td>
                  </ng-container>

                  <ng-container seFeColumnDef="sent_to">
                    <th seFeDataRef="sent_to" seFeTableSortHeader seFeHeaderCell *seFeHeaderCellDef>
                      {{ 'DISCOUNTS.DETAIL_VIEW.COLUMNS.sent_to' | translate }}
                    </th>
                    <td seFeCell *seFeCellDef="let row">{{ row.sent_to }}</td>
                  </ng-container> -->

                  <ng-container seFeColumnDef="code_as_entered">
                    <th seFeDataRef="code_as_entered" seFeTableSortHeader seFeHeaderCell *seFeHeaderCellDef>
                      {{ 'DISCOUNTS.DETAIL_VIEW.COLUMNS.code' | translate }}
                    </th>
                    <td seFeCell *seFeCellDef="let row">{{ row.code_as_entered }}</td>
                  </ng-container>

                  <ng-container seFeColumnDef="redeemed_at">
                    <th seFeDataRef="redeemed_at" seFeTableSortHeader seFeHeaderCell *seFeHeaderCellDef>
                      {{ 'DISCOUNTS.DETAIL_VIEW.COLUMNS.redeemed_at' | translate }}
                    </th>
                    <td seFeCell *seFeCellDef="let row">{{ this.discountCodeService.getRedeemedDate(row) }}</td>
                  </ng-container>

                  <ng-container seFeColumnDef="user_full_name">
                    <th seFeDataRef="user_full_name" seFeTableSortHeader seFeHeaderCell *seFeHeaderCellDef>
                      {{ 'DISCOUNTS.DETAIL_VIEW.COLUMNS.cardholder' | translate }}
                    </th>
                    <td seFeCell *seFeCellDef="let row">
                      <app-external-link
                        *ngIf="row.persona_id"
                        [url]="this.externalLinkService.getMemberUrl(row.persona_id)">
                        {{ row.user_full_name }}
                      </app-external-link>
                    </td>
                  </ng-container>

                  <ng-container seFeColumnDef="sale_number">
                    <th seFeDataRef="sale_number" seFeTableSortHeader seFeHeaderCell *seFeHeaderCellDef>
                      {{ 'DISCOUNTS.DETAIL_VIEW.COLUMNS.sale' | translate }}
                    </th>
                    <td seFeCell *seFeCellDef="let row">
                      <app-external-link
                        *ngIf="row.sale_number"
                        [url]="this.externalLinkService.getSaleUrl(row.sale_number, row.sale_type)">
                        {{ row.sale_number }}
                      </app-external-link>
                    </td>
                  </ng-container>

                  <ng-container seFeColumnDef="discount_total">
                    <th seFeDataRef="discount_total" seFeTableSortHeader seFeHeaderCell *seFeHeaderCellDef>
                      {{ 'DISCOUNTS.DETAIL_VIEW.COLUMNS.discounted' | translate }}
                    </th>
                    <td seFeCell *seFeCellDef="let row">{{ this.discountCodeService.getRedeemedValue(row) }}</td>
                  </ng-container>

                  <!-- <ng-container seFeColumnDef="more">
                    <th seFeHeaderCell *seFeHeaderCellDef></th>
                    <td seFeCell *seFeCellDef="let row">
                      //(click)=""
                      <se-fe-icon-button>
                        <se-fe-icon
                          seFeDataName="more"
                          seFeDataSize="700"
                          [seFeDataAriaLabel]="'more'">
                        </se-fe-icon>
                      </se-fe-icon-button>
                    </td>
                  </ng-container> -->

                  <tr seFeHeaderRow *seFeHeaderRowDef="displayedColumns.value; sticky: false"></tr>
                  <tr seFeRow *seFeRowDef="let row; columns: displayedColumns.value"></tr>
                </table>

                <se-fe-pagination
                  [seFeDataPaginationOptions]="paginationOptions"
                  (seFePaginationChange)="handlePagination($event)"></se-fe-pagination>
              </div>
            </div>
          </se-fe-card-content>
        </se-fe-card>
      </se-fe-grid-cell>

      <se-fe-grid-cell seFeGridOpts="1/2@desktop last@desktop 1/2@tablet last@tablet  1/1@mobile first@mobile">
        <se-fe-card>
          <se-fe-card-header
            [seFeDataTitle]="'DISCOUNTS.DETAIL_VIEW.SUMMARY.title' | translate"
            seFeDataHeavyBorder="true">
            <div seFeCardHeaderActions>
              <button
                class="se-low-button"
                data-dd-action-name="Edit Discount"
                [routerLink]="['/discounts', discountID, useV2 ? 'editv2' : 'edit']">
                {{ 'DISCOUNTS.DETAIL_VIEW.SUMMARY.action' | translate }}
              </button>
            </div>
          </se-fe-card-header>

          <se-fe-card-content *ngIf="discountDataLoading">
            <se-fe-spinner
              [seFeDataSize]="900"
              [seFeDataCenter]="true"
              class="se-fe-spinner--discountDataLoading"></se-fe-spinner>
          </se-fe-card-content>

          <div class="summary-text" [innerHtml]="discountSummary"></div>
        </se-fe-card>
      </se-fe-grid-cell>
    </se-fe-grid>

    <!-- PERFORMANCE Table location. Moved up for now.
      <se-fe-grid seFeGridOpts="snug">
      </se-fe-grid> -->
  </div>
</se-fe-page>
